import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Customers from '../components/Customers';
import Section, { ColumnsSection } from '../components/Section';
import LinkButton from '../components/LinkButton';
import { MainTitle, SectionTitle }  from '../components/Titles';
import Activity from '../components/Activity';
import MainImageSection from '../components/MainImageSection';

import { colors } from '../shared/styles';

const OffreMainImageSection = styled(MainImageSection)`
  @media screen and (max-width: 425px) {
    background-position : 14%;
  }
`

const Column = styled.div`
  flex : 1;
  text-align : justify;
  padding-top : 12rem;
  font-size : 1.6rem;
  margin-left : 4rem;
  margin-right : 4rem;
  letter-spacing : 0.1rem;
  line-height : 3rem;

  &:first-child {
    margin-left : 0;
  }

  &:last-child {
    margin-right : 0;
  }
`;

const ActivitySection = styled(ColumnsSection)`
  flex-wrap : wrap;
  justify-content : flex-start;
  margin-right : -3rem;
  margin-left : -3rem;
  margin-top : 3rem;
  margin-bottom : 6rem;
`;

const ImmersiveActivity = styled(Activity)`
  max-width : 33%;

  @media screen and (max-width: 425px) {
    max-width : 100%;
  }
`;

const CollaborativeActivity = styled(Activity)`
  max-width : 50%;

  @media screen and (max-width: 425px) {
    max-width : 100%;
  }
`;


const Page = () => (
  <Layout>
    <SEO title="Offre" />
    <OffreMainImageSection
      image={require('../images/offre_image.jpg')}
      mobileImage={require('../images/offre_image_mobile.jpg')}
      >
      <MainTitle
        strings={[
          "Des approches qui combinent la rencontre physique et la discussion online"
        ]}
        />

    </OffreMainImageSection>
    <SectionTitle>
      Approches immersives
    </SectionTitle>
    <ActivitySection>
      <ImmersiveActivity
        image={require('../images/communautes.png')}
        title="Communautés de clients online">
        Créer un dialogue continu avec vos clients, comprendre leurs parcours et leurs expériences, identifier leurs pain points et leurs attentes et tester avec eux de nouvelles idées.
      </ImmersiveActivity>
      <ImmersiveActivity
        image={require('../images/ethno.png')}
        title="Entretiens ethnographiques filmés">
        Entrer dans l’intimité de vos clients ou collaborateurs, comprendre leurs besoins réels et leurs motivations, et produire du contenu audiovisuel à destination des équipes internes ou en communication externe (réseaux sociaux…).
      </ImmersiveActivity>
      <ImmersiveActivity
        image={require('../images/social_listening.png')}
        title="Netnographies ou social listening">
        Analyser les comportements de vos clients, de vos prospects, ou d’une communauté sur le web, comprendre leur style de vie, leurs préférences d’achat, leurs influences et leurs parcours d’achat sur Internet.
      </ImmersiveActivity>
      <ImmersiveActivity
        image={require('../images/in_situ.png')}
        title="Observations in-situ">
        Comprendre l’expérience vécue par vos clients en situation, analyser leurs comportements d’achats directement en magasin.
      </ImmersiveActivity>
      <ImmersiveActivity
        image={require('../images/learning_trips.png')}
        title="Learning trips">
        Embarquer vos équipes dans une expérience immersive sur mesure en fonction de vos problématiques (ex : univers des start-ups, des incubateurs, des fablabs, vivre une expérience client mémorable, expérimenter les transformations du monde du travail...).
      </ImmersiveActivity>
      <ImmersiveActivity
        image={require('../images/ux.png')}
        title="Parcours utilisateurs et UX">
        Pour identifier rapidement les écueils ergonomiques d’un site web ou d’une application mobile, pour retravailler un parcours de navigation ou développer de nouvelles fonctionnalités.
      </ImmersiveActivity>
      <ImmersiveActivity
        image={require('../images/benchmark.png')}
        title="Benchmark 360°">
        Pour s’immerger dans votre marché et analyser ses grandes tendances en croisant le regard de vos clients finaux, de vos collaborateurs, et de vos principaux concurrents.
      </ImmersiveActivity>
      <ImmersiveActivity
        image={require('../images/mais_aussi.png')}
        title="Mais aussi...">
        Des approches qualitatives plus classiques (entretiens individuels, focus groupes…) sur des sujets tactiques : test d’idée ou de concept, test de communication, test produit...
      </ImmersiveActivity>
    </ActivitySection>
    <SectionTitle>
      Approches collaboratives
    </SectionTitle>
    <ActivitySection>
      <CollaborativeActivity
        image={require('../images/atelier.png')}
        title="Des ateliers de co-construction et des workshops d’innovation">
        Impliquant vos équipes et/ou vos clients, en présentiel ou online<br />
        &gt;	Pour travailler autour d’un challenge créatif lié à l’innovation ou à l’expérience de vos clients<br/>
        &gt;	Pour redéfinir votre « brand purpose » afin de lancer des innovations porteuses de sens et de valeurs fortes pour vos clients.
      </CollaborativeActivity>
      <CollaborativeActivity
        image={require('../images/focus_groupes.png')}
        title="Des focus groupes créatifs">
        Mobilisant des techniques d’animation inspirées des « Innovation Games » pour tester et retravailler une idée, un concept ou un nouveau produit       </CollaborativeActivity>
      <CollaborativeActivity
        image={require('../images/codesign.png')}
        title="Des ateliers de Co-design">
        Réunissant des utilisateurs finaux, un ergonome et un développeur pour identifier les fonctionnalités prioritaires à mettre en place dans le cadre du développement d’un site web ou d’une appli mobile. Des wireframes peuvent être réalisés en live lors de ces ateliers.       </CollaborativeActivity>
      <CollaborativeActivity
        image={require('../images/crash_test.png')}
        title="Des ateliers 'crash tests' à destination des PME et des start-ups">
        Pour aller à la rencontre des futurs utilisateurs d’un produit ou service, identifier la pertinence et l’attractivité d’une idée ou d’un concept, se confronter à son marché ou à sa cible et identifier des opportunités de développement.      </CollaborativeActivity>
    </ActivitySection>
  </Layout>
)

export default Page
